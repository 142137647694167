import { useSelector } from 'react-redux'

function GetScoringGrid(grid)
{

// always returned high, low
let college = [
    [[0,0], [4,3], [8,3], [11,4], [15,4], [19,5], [23,5], [26,6], [30,6], [34,7], [38,7], [41,8], [45,8], [49,9], [53,9], [56,10], [60,10], [64,11], [68,11], [71,12], [75,13]],
    [[0,0], [6,6], [10,8], [13,9], [17,9], [21,10], [25,10], [28,11], [32,11], [36,12], [40,12], [43,13], [47,13], [51,14], [55,14], [58,15], [62,15], [66,16], [70,16], [73,17], [77,18]],
    [[0,0], [0,0], [12,12], [15,14], [19,14], [23,15], [27,15], [30,16], [34,16], [38,17], [42,17], [45,18], [49,18], [53,19], [57,19], [60,20], [64,20], [68,21], [72,21], [75,22], [79,23]],
    [[0,0], [0,0], [0,0], [17,17], [21,19], [25,20], [29,20], [32,21], [36,21], [40,22], [44,22], [47,23], [51,23], [55,24], [59,24], [62,25], [66,25], [70,26], [74,26], [77,27], [81,28]],
    [[0,0], [0,0], [0,0], [0,0], [23,23], [27,25], [31,25], [34,26], [38,26], [42,27], [46,27], [49,28], [53,28], [57,29], [61,29], [64,30], [68,30], [72,31], [76,31], [79,32], [83,33]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [29,29], [33,30], [36,31], [40,31], [44,32], [48,32], [51,33], [55,33], [59,34], [63,34], [66,35], [70,35], [74,36], [78,36], [81,37], [85,38]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [35,35], [38,36], [42,36], [46,37], [50,37], [53,38], [57,38], [61,39], [65,39], [68,40], [72,40], [76,41], [80,41], [83,42], [87,43]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [40,40], [44,41], [48,42], [52,42], [55,43], [59,43], [63,44], [67,44], [70,45], [74,45], [78,46], [82,46], [85,47], [89,48]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [46,46], [50,47], [54,47], [57,48], [61,48], [65,49], [69,49], [72,50], [76,50], [80,51], [84,51], [87,52], [91,53]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [52,52], [56,52], [59,53], [63,53], [67,54], [71,54], [74,55], [78,55], [82,56], [86,56], [89,57], [93,58]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [58,58], [61,58], [65,58], [69,59], [73,59], [76,60], [80,60], [84,61], [88,61], [91,62], [95,63]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [63,63], [67,63], [71,64], [75,64], [78,65], [82,65], [86,66], [90,66], [93,67], [97,68]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [69,69], [73,69], [77,69], [80,70], [84,70], [88,71], [92,71], [95,72], [99,73]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [75,75], [79,74], [82,75], [86,75], [90,76], [94,76], [97,77], [101,78]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [81,81], [84,80], [88,80], [92,81], [96,81], [99,82], [103,83]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [86,86], [90,85], [94,86], [98,86], [101,87], [105,88]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [92,92], [96,92], [100,92], [103,93], [107,93]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [98,98], [102,98], [105,99], [109,99]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [104,104], [107,104], [111,105]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [109,109], [113,110]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [115,115]]
  ];

  let proLo = [
    [[0,0], [5,2], [11,3], [16,4], [21,5], [26,6], [31,7], [37,9], [42,10], [48,12], [53,13], [59,15], [65,17], [71,19], [76,20], [81,22], [87,23], [92,24], [96,26], [103,27], [109,29]],
    [[0,0], [7,7], [13,9], [18,10], [23,11], [28,12], [33,13], [39,15], [44,16], [50,18], [55,19], [61,21], [67,23], [73,25], [78,28], [83,27], [89,29], [94,30], [98,32], [105,33], [111,35]],
    [[0,0], [0,0], [15,15], [20,16], [25,17], [30,18], [35,19], [41,21], [46,22], [52,24], [57,25], [63,27], [69,29], [75,31], [80,32], [85,33], [91,35], [96,36], [100,38], [107,39], [113,41]],
    [[0,0], [0,0], [0,0], [22,22], [27,23], [32,24], [37,25], [43,27], [48,28], [54,30], [59,31], [65,33], [71,35], [77,37], [82,38], [87,39], [93,41], [98,42], [102,44], [109,45], [115,47]],
    [[0,0], [0,0], [0,0], [0,0], [29,29], [34,30], [39,31], [45,33], [50,34], [56,36], [61,37], [67,39], [73,41], [79,43], [84,44], [89,45], [95,47], [100,48], [106,50], [111,51], [117,53]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [36,36], [41,37], [47,39], [52,40], [58,42], [63,43], [69,45], [75,47], [81,49], [86,50], [91,51], [97,53], [102,54], [108,56], [113,57], [119,59]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [43,43], [49,45], [54,46], [60,48], [65,49], [71,51], [77,53], [83,55], [88,56], [93,57], [99,59], [104,60], [110,62], [115,63], [121,65]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [51,51], [56,52], [62,54], [67,55], [73,57], [79,59], [85,61], [90,62], [95,63], [101,65], [106,66], [112,68], [117,69], [123,71]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [58,58], [64,60], [69,61], [75,63], [81,65], [87,67], [92,68], [97,69], [103,71], [108,72], [114,74], [119,75], [125,77]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [66,66], [71,67], [77,69], [83,71], [89,73], [94,74], [99,75], [105,77], [110,78], [116,80], [121,81], [127,83]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [73,73], [79,75], [85,77], [91,79], [96,80], [101,81], [107,83], [112,84], [118,86], [123,87], [129,89]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [81,81], [87,83], [93,85], [98,86], [103,87], [109,89], [114,90], [120,92], [125,93], [131,95]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [89,89], [95,91], [100,92], [105,93], [111,95], [116,96], [122,98], [127,99], [133,101]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [97,97], [102,98], [107,99], [113,101], [118,102], [124,104], [129,105], [135,107]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [104,104], [109,105], [115,107], [120,108], [126,110], [131,111], [137,113]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [111,111], [117,113], [122,114], [128,116], [133,117], [139,119]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [119,119], [124,120], [130,122], [135,123], [141,125]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [126,126], [132,128], [137,129], [143,131]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [134,134], [139,135], [145,137]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [141,141], [147,143]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [149,149]]
  ];

  let proMidLo = [
    [[0,0], [6,2], [13,5], [19,7], [25,9], [31,11], [38,14], [44,16], [50,18], [56,20], [63,23], [69,25], [75,27], [81,29], [87,31], [93,33], [99,35], [105,37], [111,39], [116,42], [124,44]],
    [[0,0], [8,8], [15,11], [21,13], [27,15], [33,17], [40,20], [46,22], [52,24], [58,26], [65,29], [71,31], [77,33], [83,35], [89,37], [95,39], [101,41], [107,43], [113,45], [118,48], [126,50]],
    [[0,0], [0,0], [17,17], [23,19], [29,21], [35,23], [42,26], [48,28], [54,30], [60,32], [67,35], [73,37], [79,39], [85,41], [91,43], [97,45], [103,47], [109,49], [115,51], [120,54], [128,56]],
    [[0,0], [0,0], [0,0], [25,25], [31,27], [37,29], [44,32], [50,34], [56,36], [62,38], [69,41], [75,43], [81,45], [87,47], [93,49], [99,51], [105,53], [111,55], [117,57], [122,60], [130,62]],
    [[0,0], [0,0], [0,0], [0,0], [33,33], [39,35], [46,38], [52,40], [58,42], [64,44], [71,47], [77,49], [83,51], [89,53], [95,55], [101,57], [107,59], [113,61], [119,63], [124,66], [132,68]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [41,41], [48,44], [54,46], [60,48], [66,50], [73,53], [79,55], [85,57], [91,59], [97,61], [103,63], [109,65], [115,67], [121,69], [126,72], [134,74]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [50,50], [56,52], [62,54], [68,56], [75,59], [81,61], [87,63], [93,65], [99,67], [105,69], [111,71], [117,73], [123,75], [128,78], [136,80]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [58,58], [64,60], [70,62], [77,65], [83,67], [89,69], [95,71], [101,73], [107,75], [113,77], [119,79], [125,81], [130,84], [138,86]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [66,66], [72,68], [79,71], [85,73], [91,75], [97,77], [103,79], [109,81], [115,83], [121,85], [127,87], [132,90], [140,92]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [74,74], [81,77], [87,79], [93,81], [99,83], [105,85], [111,87], [117,89], [123,91], [129,93], [134,96], [142,98]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [83,83], [89,85], [95,87], [101,89], [107,91], [113,93], [119,95], [125,97], [131,99], [136,102], [144,104]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [91,91], [97,93], [103,95], [109,97], [115,99], [121,101], [127,103], [133,105], [138,108], [146,110]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [99,99], [105,101], [111,103], [117,105], [123,107], [129,109], [135,111], [140,114], [148,116]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [107,107], [113,109], [119,111], [125,113], [131,115], [137,117], [142,120], [150,122]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [115,115], [121,117], [127,119], [133,121], [139,123], [144,126], [152,128]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [123,123], [129,125], [135,127], [141,129], [146,132], [154,134]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [131,131], [137,133], [143,135], [148,138], [156,140]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [139,139], [145,141], [150,144], [158,146]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [147,147], [152,150], [160,152]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [156,156], [162,158]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [164,164]]
  ];

  let proMidHi = [
    [[0,0], [10,7], [15,9], [21,11], [28,12], [35,15], [42,18], [49,21], [56,24], [63,28], [70,30], [77,33], [84,36], [91,39], [97,41], [103,43], [109,45], [115,47], [121,49], [127,51], [133,53]],
    [[0,0], [12,12], [17,14], [23,15], [30,18], [37,21], [44,24], [51,27], [58,30], [65,34], [72,36], [79,39], [86,42], [93,45], [99,47], [105,49], [111,51], [117,53], [123,55], [129,57], [135,59]],
    [[0,0], [0,0], [19,19], [25,21], [32,24], [39,27], [46,30], [53,33], [60,36], [67,39], [74,42], [81,45], [88,48], [95,51], [101,53], [107,55], [113,57], [119,59], [125,61], [131,63], [137,65]],
    [[0,0], [0,0], [0,0], [27,27], [34,30], [41,33], [48,36], [55,39], [62,42], [69,45], [76,48], [83,51], [90,54], [97,57], [103,59], [109,61], [115,63], [121,65], [127,67], [133,69], [139,71]],
    [[0,0], [0,0], [0,0], [0,0], [36,36], [43,39], [50,42], [57,45], [64,48], [71,51], [78,54], [85,57], [92,60], [99,63], [105,65], [111,67], [117,69], [123,71], [129,73], [135,75], [141,77]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [45,45], [52,48], [59,51], [66,54], [73,57], [80,60], [87,63], [94,66], [101,69], [107,71], [113,73], [119,75], [125,77], [131,79], [137,81], [143,83]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [54,54], [61,57], [68,60], [75,63], [82,66], [89,69], [96,72], [103,75], [109,77], [115,79], [121,81], [127,83], [133,85], [139,87], [145,89]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [63,63], [70,66], [77,69], [84,72], [91,75], [98,78], [105,81], [111,83], [117,85], [123,87], [129,89], [135,91], [141,93], [147,95]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [72,72], [79,75], [86,78], [93,81], [100,84], [107,87], [113,89], [119,91], [125,93], [131,95], [137,97], [143,99], [149,101]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [81,81], [88,84], [95,87], [102,90], [109,93], [115,95], [121,97], [127,99], [133,101], [139,103], [145,105], [151,107]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [90,90], [97,93], [104,96], [111,99], [117,101], [123,103], [129,105], [135,107], [141,109], [147,111], [153,113]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [99,99], [106,102], [113,105], [119,107], [125,109], [131,111], [137,113], [143,115], [149,117], [155,119]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [108,108], [115,111], [121,113], [127,115], [133,117], [139,119], [145,121], [151,123], [157,125]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [117,117], [123,119], [129,121], [135,123], [141,125], [147,127], [153,129], [159.131]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [125,125], [131,127], [137,129], [143,131], [149,133], [155,135], [161,137]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [133,133], [139,135], [145,137], [151,139], [157,141], [163,143]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [141,141], [147,143], [153,145], [159,147], [165,149]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [149,149], [155,151], [161,153], [167,155]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [157,157], [163,159], [169,161]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [165,165], [171,167]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [173,173]]
  ];

  let proHi = [
    [[0,0], [11,7], [16,9], [23,11], [31,15], [39,19], [46,22], [54,26], [62,30], [69,33], [77,37], [85,41], [92,44], [100,48], [108,52], [115,55], [123,59], [131,63], [138,66], [146,72], [154,74]],
    [[0,0], [13,13], [18,14], [25,17], [33,21], [41,25], [48,28], [56,32], [64,36], [71,39], [79,43], [87,47], [94,50], [102,54], [110,58], [117,61], [125,65], [133,69], [140,72], [148,76], [156,80]],
    [[0,0], [0,0], [20,20], [27,23], [35,27], [43,31], [50,34], [58,38], [66,42], [73,45], [81,49], [89,53], [96,56], [104,60], [112,64], [119,67], [127,71], [135,75], [142,78], [150,82], [158,86]],
    [[0,0], [0,0], [0,0], [29,29], [37,33], [45,37], [52,40], [60,44], [68,48], [75,51], [83,55], [91,59], [98,62], [106,66], [114,70], [121,73], [129,77], [137,81], [144,84], [152,88], [160,92]],
    [[0,0], [0,0], [0,0], [0,0], [39,39], [47,43], [54,46], [62,50], [70,54], [77,57], [85,61], [93,65], [100,68], [108,72], [116,76], [123,79], [131,83], [139,87], [146,90], [154,94], [162,98]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [49,49], [56,52], [64,56], [72,60], [79,63], [87,67], [95,71], [102,74], [110,78], [118,82], [125,85], [133,89], [141,93], [148,96], [156,100], [164,104]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [58,58], [66,62], [74,66], [81,69], [89,73], [97,77], [104,80], [112,84], [120,88], [127,91], [135,95], [143,99], [150,102], [158,106], [166,110]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [68,68], [76,72], [83,75], [91,79], [99,83], [106,86], [114,90], [122,94], [129,97], [137,101], [145,105], [152,108], [160,112], [168,116]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [78,78], [85,81], [93,85], [101,89], [108,92], [116,96], [124,100], [131,103], [139,107], [147,111], [154,114], [162,118], [170,122]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [87,87], [95,91], [103,95], [110,98], [118,102], [126,106], [133,109], [141,113], [149,117], [156,120], [164,124], [172,128]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [97,97], [105,101], [112,104], [120,108], [128,112], [135,115], [143,119], [151,123], [158,126], [166,130], [174,134]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [107,107], [114,110], [122,114], [130,118], [137,121], [145,125], [153,129], [160,132], [168,136], [176,140]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [116,116], [124,120], [132,124], [139,127], [147,131], [155,135], [162,138], [170,142], [178,146]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [126,126], [134,130], [141,133], [149,137], [157,141], [164,144], [172,148], [180,152]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [136,136], [143,139], [151,143], [159,147], [166,150], [174,154], [182,158]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [145,145], [153,149], [161,153], [168,156], [176,160], [184,164]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [155,155], [163,159], [170,162], [178,166], [186,170]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [165,165], [172,168], [180,172], [188,176]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [174,174], [182,178], [190,182]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [184,184], [192,188]],
    [[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [194,194]]
  ];

  if(grid == 'college') return college;
  if(grid == 'proLo') return proLo;
  if(grid == 'proMidLo') return proMidLo;
  if(grid == 'proMidHi') return proMidHi;
  if(grid == 'proHi') return proHi;

  return college;
}

function GetFriendlyGridName(grid) {
  if(grid == 'college') return "College";
  if(grid == 'proLo') return "Pro - Lo";
  if(grid == 'proMidLo') return "Pro Mid/Lo";
  if(grid == 'proHi') return "Pro Hi";
}

function GetScores(game) {
    
  let high = 0;
  let low = 0;
  
  if(game.home.space == game.away.space)
  {
    high = game.home.space;
    low = game.away.space;
  } 
  else
  {
    high = Math.max(game.home.space, game.away.space);
    low = Math.min(game.home.space, game.away.space);
  }

  let scoringGrid = GetScoringGrid(game.scoringGrid);
  
  var gameScore = scoringGrid[low][high];
  
  let homeScore = 0;
  let awayScore = 0;    

  if(game.home.space > game.away.space)
  {
    homeScore = gameScore[0] + game.home.freeThrows;
    awayScore = gameScore[1] + game.away.freeThrows;      
  }
  else
  {
    homeScore = gameScore[1] + game.home.freeThrows;
    awayScore = gameScore[0] + game.away.freeThrows;      
  }

  return {homeScore, awayScore};
}
      
export { GetScoringGrid, GetFriendlyGridName, GetScores };