import classNames from "classnames";
import { useSelector, useDispatch } from 'react-redux'
import { setSpace, addFreeThrow, removeFreeThrow, switchTimeoutTeam } from '../features/game/gameSlice'

function TeamScore(props) {
  const game = useSelector((state) => state.game)
  const dispatch = useDispatch()

  const addSpace = () => {
    let newSpace = Math.min(20, props.data.space+1);
    dispatch(setSpace({team: props.team, space: newSpace}));
  }

  const incrementFreeThrow = () => {
    dispatch(addFreeThrow(props.team));
  }

  const decreaseSpace = () => {
    let newSpace = Math.max(0, props.data.space-1);
    dispatch(setSpace({team: props.team, space: newSpace}));
  }

  const decrementFreeThrow = () => {
    dispatch(removeFreeThrow(props.team));
  }

  const callTimeout = (e) => {
    e.preventDefault();
    dispatch(switchTimeoutTeam());
  }

  return (
    <div className="text-center">
        <div className='fs-2'>{props.name}</div>
        <div className='score'>{props.score}</div>

        <div className="row mt-3">
          <div className="col-6">
            <div><img src="/images/arrow-up.png" onClick={addSpace} /></div>
            
            <div className="my-3">
              <span className={classNames('fs-5', {error:props.data.space >= props.data.cap})}>{props.data.space} / {props.data.cap}</span>
            </div>
            
            <div><img src="/images/arrow-down.png" onClick={decreaseSpace} /></div>
          </div>

          <div className="col-6">
            <div><img src="/images/arrow-up.png" onClick={incrementFreeThrow} /></div>
            <div className="fs-5 my-3">{props.data.freeThrows}</div>
            <div><img src="/images/arrow-down.png" onClick={decrementFreeThrow} /></div>
          </div>
        </div>       

        {props.timeoutTeam == props.team && <div className="my-3"><img src="/images/clipboard-icon.png" onClick={callTimeout} /></div>}
    </div> 
  );
}
  
  export default TeamScore;