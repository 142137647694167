import './App.scss';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
import Scoreboard from './components/scoreboard';
import { GetScoringGrid, GetFriendlyGridName } from './components/scoring-grids';
import { useSelector, useDispatch } from 'react-redux'
import { setGrid } from './features/game/gameSlice'

if('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js')
      .then((reg) => console.log('serviceWorker registered', reg))
      .catch((err) => console.log('serviceWorker not registered', err));
}

function App() {
  const game = useSelector((state) => state.game)
  const dispatch = useDispatch()

  const setScoringGrid = (grid) => {
    dispatch(setGrid(grid));
  }

  return (
    <div className="App container-fluid">
      
      <div className='row'>
        {/* outer row */}

        <div className="col">
          {game.scoringGrid && 
          <>
            <div><strong>Scoring:</strong> {GetFriendlyGridName(game.scoringGrid)}</div>
            <Scoreboard />
          </>
          }

          {!game.scoringGrid && 
          <div className="text-center my-5">
            <DropdownButton
            title="Select Scoring Grid"
            id="dropdown-menu-align-right"
            onSelect={setScoringGrid}>
                    <Dropdown.Item eventKey="college">College</Dropdown.Item>
                    <Dropdown.Item eventKey="proLo">Pro - Lo</Dropdown.Item>
                    <Dropdown.Item eventKey="proMidLo">Pro - Mid/Lo</Dropdown.Item>
                    <Dropdown.Item eventKey="proMidHi">Pro - Mid/Hi</Dropdown.Item>
                    <Dropdown.Item eventKey="proHi">Pro - Hi</Dropdown.Item>
            </DropdownButton>
           </div>
          }
        </div>

        

        {/* outer row */}
      </div>
       
      
    </div>
  );
}

export default App;
