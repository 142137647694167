import { useState, useEffect } from 'react';
import SideNav from './side-nav';
import TeamScore from "./teamscore.js";
import { useSelector } from 'react-redux'
import { GetScores } from './scoring-grids';

function Scoreboard(props) {
  const game = useSelector((state) => state.game)
  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);

  useEffect(() => {
    let score = GetScores(game);
    setHomeScore(score.homeScore);
    setAwayScore(score.awayScore);
  },[
    game.home.space, 
    game.away.space,
    game.home.freeThrows,
    game.away.freeThrows
  ])
  
    return (
      <>
        <div className="container mt-3">
            <div className="row">
                <div className='col-6' style={{borderRight:'1px solid #CECECE'}}>
                <TeamScore name="Away" 
                        data={game.away} 
                        team='away'
                        timeoutTeam={game.timeoutTeam} 
                        score={awayScore}
                        />
                </div>

                <div className='col-6'>
                <TeamScore name="Home" 
                        data={game.home} 
                        team='home'
                        timeoutTeam={game.timeoutTeam} 
                        score={homeScore}
                        />
                    
                </div>
            </div>

            <SideNav 
              onResetGame={props.onResetGame} 
              onResetCaps={props.onResetCaps} 
              gameData={props.gamedata}  
              homeScore={homeScore}
              awayScore={awayScore}
              />
        </div>

      </>
    );
  }
  
  export default Scoreboard;