import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faForwardStep, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { resetGame, resetCaps } from '../features/game/gameSlice'
import { useDispatch, useSelector } from 'react-redux'

function SideNav(props) {
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game)

  const [showResetGame, setShowResetGame] = useState(false);
  const [showInfo, setshowInfo] = useState(false);
  const [showResetCaps, setShowResetCaps] = useState(false);
  const [showFacsLeftButton, setShowFacsLeftButton] = useState(false);

  const handleCloseResetGame = () => setShowResetGame(false);
  const handleShowResetGame = () => setShowResetGame(true);

  const handleResetGame = () => {
    dispatch(resetGame());
    setShowResetGame(false);
  }
  const handleResetCaps = (adjustForRemainingFacs) => {
    dispatch(resetCaps({
      adjustForRemainingFacs
    }));
    setShowResetCaps(false);
  }


  const handleCloseResetCaps = () => setShowResetCaps(false);
  const handleShowResetCaps = () => {
    setShowFacsLeftButton(game.away.space == game.away.cap 
      || game.home.space == game.home.cap);
    setShowResetCaps(true);
  }
  

  return (
    <>
    <div className="col text-center mt-3">

              <button style={{width:'100px'}} className="btn btn-warning m-1 fs-2" onClick={handleShowResetGame}>
                <FontAwesomeIcon icon={faCirclePlus} />
              </button>

              <button style={{width:'100px'}} className="btn btn-danger m-1 fs-2" onClick={handleShowResetCaps}>
                <FontAwesomeIcon icon={faForwardStep} /></button>

              <button style={{width:'100px'}} className="btn btn-info m-1 fs-2" onClick={() => setshowInfo(true)}>
                <FontAwesomeIcon icon={faCircleInfo} /></button>
        </div>

        {/* Reset Scoring Caps */}
        <Modal show={showResetCaps} onHide={handleCloseResetCaps}>
        <Modal.Header closeButton>
          <Modal.Title>Update Scoring Caps Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showFacsLeftButton ? "Is there more than 1 FAC left unplayed?" : "Are you sure you want to update the scoring caps?"}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleCloseResetCaps}>Cancel</Button>

          {showFacsLeftButton && 
          <>
            <Button variant="danger" onClick={() => handleResetCaps(false)}>No</Button> 
            <Button variant="success" onClick={() =>handleResetCaps(true)}>Yes</Button>
          </>
          }

          {!showFacsLeftButton && 
          <Button variant="success" onClick={() => handleResetCaps(false)}>Yes</Button> }
        </Modal.Footer>
      </Modal>

        {/* Reset game */}
        <Modal show={showResetGame} onHide={handleCloseResetGame}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Game Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to reset this game?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseResetGame}>No</Button>
          <Button variant="success" onClick={handleResetGame}>Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showInfo} onHide={() => setshowInfo(false)}>
        <Modal.Header closeButton>
          <Modal.Title>HoopScore (beta)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>To start a new game, hit the yellow "plus" button and choose your scoring grid. </p>
          <p>
            Simply use the up/down arrows to change the scoring track and free throws made.</p>
          <p>
            When the quarter is over
            hit the red "next" button to reset the scoring caps.  HoopScore will take care of all post-quarter 
            management for you.  
          </p>

          <p>When a coach result is flipped, you can keep track of who gets the next subtitution and roll by clicking
            the bench cam clipboard icon.  It will flip to the the opposite team on each click.
          </p>

          <p><i>Thanks to Cooper Gilbert and Paul Salzgeber for help with data entry!</i></p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SideNav;