import { createSlice } from '@reduxjs/toolkit'
import { loadState, saveState } from "../../browser-storage";
import { GetScores } from '../../components/scoring-grids';

const stateKey = "gameState";
let initialState = loadState(stateKey);
let defaultState = {
    home: {
        space: 0,
        freeThrows: 0,
        cap: 5,  
        score: 0,  
    },
        away: {
        space: 0,
        freeThrows: 0,
        cap: 5,
        score: 0, 
    },
    period: 1,
    timeoutTeam: 'home',
    scoringGrid: null
}
if(!initialState) initialState = defaultState;

export const gameSlice = createSlice({
  name: 'game',
  initialState: initialState,
  reducers: {
    setSpace: (state, action) => {
        (action.payload.team == 'home' ? state.home.space = action.payload.space : state.away.space = action.payload.space )
        saveState(stateKey, state)
    },
    setGrid: (state, action) => {
        state.scoringGrid = action.payload
        saveState(stateKey, state)
    },
    addFreeThrow: (state, action) => {
        (action.payload == 'home' ? state.home.freeThrows++ : state.away.freeThrows++ )
        saveState(stateKey, state)
    },
    removeFreeThrow: (state, action) => {
        saveState(stateKey, state)

        if(action.payload == 'home')
        {
            let newFreeThrowValue = state.home.freeThrows - 1;
            if(newFreeThrowValue >= 0) state.home.freeThrows = newFreeThrowValue;
        }
        else
        {
            let newFreeThrowValue = state.away.freeThrows - 1;
            if(newFreeThrowValue >= 0) state.away.freeThrows = newFreeThrowValue;
        }
    },
    switchTimeoutTeam: (state) => {
        state.timeoutTeam = state.timeoutTeam == 'home' ? 'away' : 'home';
        saveState(stateKey, state)
    },
    resetGame:(state) => {
        saveState(stateKey, defaultState);
        return defaultState;
    },

    /*
        The issue RN is that we pass in the score, and then we make adjustments to the spaces
        based on minimum scoring or hitting the scoring cap.  Thus, after those adjustments, 
        the score is not updated, so the final check for adjusting the caps for trailing or
        lead are not correct.  We need to calculate the NEW score after these adjustments. 
    */
    resetCaps(state, action) {
        console.log(action);
        let unused = (state.home.cap - state.home.space) + (state.away.cap - state.away.space);

        let minimumAdjust = 0;

        // If the unused spaces amount is >= 7, we have to add one to each team for minimum scoring rules
        if(unused >= 9) 
        {
            minimumAdjust = 2;
        }
        else if(unused >= 7)
        {
            minimumAdjust = 1;
        }

        state.home.space+=minimumAdjust;
        state.away.space+=minimumAdjust;

        // If there's more than 1 fac left and we hit the scoring cap, make the bonus adjustment
        if(action.payload.adjustForRemainingFacs)
        {
            let homeHasMetCap = state.home.space >= state.home.cap;
            let awayHasMetCap = state.away.space >= state.away.cap;

            if(homeHasMetCap) state.away.space++;
            if(awayHasMetCap) state.home.space++;
        }

        let scores = GetScores(state);

        // now adjust the caps for trailing or tied
        if(scores.homeScore == scores.awayScore)
        {
            state.home.cap = state.home.space + 5;
            state.away.cap = state.away.space + 5;
        } 
        else if(scores.homeScore > scores.awayScore)
        {
            state.home.cap = state.home.space + 5;
            state.away.cap = state.away.space + 6;
        }
        else
        {
            state.home.cap = state.home.space + 6;
            state.away.cap = state.away.space + 5;
        }

        saveState(stateKey, state);
    }
    
  },
})

// Action creators are generated for each case reducer function
export const { 
    setGrid, 
    setSpace, 
    addFreeThrow, 
    removeFreeThrow, 
    switchTimeoutTeam, 
    resetGame, 
    resetCaps
} = gameSlice.actions

export default gameSlice.reducer