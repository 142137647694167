import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './features/counter/counterSlice'
import gameReducer from './features/game/gameSlice'

import { loadState } from "./browser-storage";

export default configureStore({
  reducer: {
    counter: counterReducer,
    game: gameReducer,
  },
})